import React from 'react';
import styled from 'styled-components';

import ArticlesHero from '@articles/Articles.Hero';
import ArticlesList from '@articles/Articles.List';
import ArticlesContextProvider from '@articles/Articles.List.Context';
import { Section, Container } from '@components/global';
import Paginator from '@common/Paginator';

const Posts = ({ posts, accent, paginateContext }) => {
  return (
    <Section id="writing" accent={accent}>
      <Container>
        <ArticlesContextProvider>
          <ArticlesHero
            numPosts={posts.length}
            showOnlyFeatured={!paginateContext}
          />
          <ArticlesList posts={posts} />
          {paginateContext && (
            <ArticlesPaginator show={paginateContext.pageCount > 1}>
              <Paginator {...paginateContext} />
            </ArticlesPaginator>
          )}
        </ArticlesContextProvider>
      </Container>
    </Section>
  );
};

const ArticlesPaginator = styled.div`
  margin-top: 95px;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-top: 0;
  }

  ${(p) => !p.show && `display: none;`}
`;

export default Posts;
