import React, { useContext } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery, Link } from 'gatsby';

import TilesIcon from '@icons/Tiles';
import RowsIcon from '@icons/Rows';

import { GridLayoutContext } from '@articles/Articles.List.Context';

const postCountQuery = graphql`
  {
    allMdx(filter: { frontmatter: { draft: { ne: true } } }) {
      totalCount
    }
  }
`;

const ArticlesHero = (props) => {
  const {
    gridLayout = 'tiles',
    hasSetGridLayout,
    setGridLayout,
  } = useContext(GridLayoutContext);

  const tilesIsActive = hasSetGridLayout && gridLayout === 'tiles';
  const { showOnlyFeatured, numPosts } = props;
  const postCountQueryResult = useStaticQuery(postCountQuery);
  const {
    allMdx: { totalCount },
  } = postCountQueryResult;

  return (
    <ControlsContainer>
      {numPosts > 0 && (
        <GridControlsContainer>
          <GridButton
            onClick={() => setGridLayout('tiles')}
            active={tilesIsActive}
            data-a11y="false"
            title="Show articles in Tile grid"
            aria-label="Show articles in Tile grid"
          >
            <TilesIcon />
          </GridButton>
          <GridButton
            onClick={() => setGridLayout('rows')}
            active={!tilesIsActive}
            data-a11y="false"
            title="Show articles in Row grid"
            aria-label="Show articles in Row grid"
          >
            <RowsIcon />
          </GridButton>
        </GridControlsContainer>
      )}
      {totalCount > 0 && showOnlyFeatured && (
        <p>
          <StyledLink to="/posts">See all posts »</StyledLink>
        </p>
      )}
    </ControlsContainer>
  );
};

const GridButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: transparent;
  transition: background 0.25s;
  &:not(:last-child) {
    margin-right: 30px;
  }
  &:hover {
    background: ${(p) => p.theme.color.regular};
  }
  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -10%;
    top: -10%;
    width: 120%;
    height: 120%;
    border: 2px solid ${(p) => p.theme.color.regular};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 50%;
  }
  svg {
    opacity: ${(p) => (p.active ? 1 : 0.25)};
    transition: opacity 0.2s;
    path {
      fill: ${(p) => p.theme.color.font.regular};
    }
  }
`;

const GridControlsContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${(p) => p.theme.screen.md}) {
    display: none;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;

  @media (max-width: ${(p) => p.theme.screen.lg}) {
    margin-bottom: 80px;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    margin-bottom: 60px;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.color.font.regular};
  }
`;

export default ArticlesHero;
