import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { graphql, StaticQuery, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import ImagePlaceholder from '@articles/Image.Placeholder';
import { GridLayoutContext } from '@articles/Articles.List.Context';
import RandomGenerator from '@util/RandomGenerator';

const ArticlesList = ({ posts, alwaysShowAllDetails }) => {
  const hasOnlyOneArticle = posts.length === 1;
  const {
    gridLayout = 'tiles',
    hasSetGridLayout,
    getGridLayout,
  } = useContext(GridLayoutContext);

  useEffect(() => getGridLayout(), [getGridLayout]);

  if (!posts) return null;
  if (posts.length === 0) return <p>Sorry! No featured posts yet.</p>;

  /**
   * We're taking the flat array of articles [{}, {}, {}...]
   * and turning it into an array of pairs of articles [[{}, {}], [{}, {}], [{}, {}]...]
   * This makes it simpler to create the grid we want
   */
  const postPairs = posts.reduce((result, value, index, array) => {
    if (index % 2 === 0) {
      result.push(array.slice(index, index + 2));
    }
    return result;
  }, []);

  return (
    <ArticlesListContainer
      style={{ opacity: hasSetGridLayout ? 1 : 0 }}
      alwaysShowAllDetails={alwaysShowAllDetails}
    >
      {postPairs.map((ap, index) => {
        const isEven = index % 2 !== 0;
        const isOdd = index % 2 !== 1;

        return (
          <List
            key={index}
            gridLayout={gridLayout}
            hasOnlyOneArticle={hasOnlyOneArticle}
            reverse={isEven}
          >
            <ListItem post={ap[0]} narrow={isEven} />
            <ListItem post={ap[1]} narrow={isOdd} />
          </List>
        );
      })}
    </ArticlesListContainer>
  );
};

export default ArticlesList;

const ListItem = ({ post, narrow }) => {
  const { gridLayout } = useContext(GridLayoutContext);

  if (!post) return null;

  const hasOverflow = narrow && post.frontmatter.title.length > 35;
  const imageSource = post.fields
    ? post.fields.coverImageRelative.childImageSharp.gatsbyImageData
    : null;
  const imageAlt = post.fields ? post.fields.coverImageRelative.name : null;
  const hasHeroImage =
    imageSource &&
    Object.keys(imageSource).length !== 0 &&
    imageSource.constructor === Object;

  let imagePlaceholder = <ImagePlaceholder />;
  if (!hasHeroImage) {
    imagePlaceholder = (
      <StaticQuery
        query={graphql`
          {
            artImages: allFile(filter: { sourceInstanceName: { eq: "art" } }) {
              edges {
                node {
                  childImageSharp {
                    gatsbyImageData(
                      width: 960
                      placeholder: TRACED_SVG
                      layout: CONSTRAINED
                    )
                  }
                  name
                }
              }
            }
          }
        `}
        render={(data) => {
          const { artImages } = data;
          const { edges } = artImages;
          const randomPosition = RandomGenerator(0, edges.length - 1);
          const randomArtImage = edges[randomPosition].node;
          return (
            <DynamicImage
              image={randomArtImage.childImageSharp.gatsbyImageData}
              alt={randomArtImage.name}
            />
          );
        }}
      />
    );
  }

  return (
    <ArticleLink to={post.frontmatter.slug} data-a11y="false">
      <Item gridLayout={gridLayout}>
        <ImageContainer narrow={narrow} gridLayout={gridLayout}>
          {hasHeroImage ? (
            <GatsbyImage image={imageSource} alt={imageAlt} />
          ) : (
            imagePlaceholder
          )}
        </ImageContainer>
        <div>
          {post.frontmatter.tag && <Tag>{post.frontmatter.tag}</Tag>}
          <Title
            dark
            hasOverflow={hasOverflow}
            gridLayout={gridLayout}
            tagged={post.frontmatter.tag ? true : false}
          >
            {post.frontmatter.title}
          </Title>
          <Excerpt
            narrow={narrow}
            hasOverflow={hasOverflow}
            gridLayout={gridLayout}
          >
            {post.frontmatter.excerpt}
          </Excerpt>
          <MetaData>
            {post.frontmatter.date} · {post.timeToRead} min read
          </MetaData>
        </div>
      </Item>
    </ArticleLink>
  );
};

const wide = '1fr';
const narrow = '457px';

const ArticlesListContainer = styled.div`
  transition: opacity 0.25s;
  ${(p) => p.alwaysShowAllDetails && showDetails}
`;

const List = styled.div`
  ${(p) => (p.gridLayout === 'tiles' ? listTile : listRow)}
`;

const Item = styled.div`
  ${(p) => (p.gridLayout === 'rows' ? listItemRow : listItemTile)}
`;

const limitToTwoLines = css`
  text-overflow: ellipsis;
  overflow-wrap: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;

  @media (max-width: ${(p) => p.theme.screen.md}) {
    -webkit-line-clamp: 3;
  }
`;

const listItemRow = (p) => css`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 488px;
  grid-column-gap: 96px;
  grid-template-rows: 1;
  align-items: center;
  position: relative;
  margin-bottom: 50px;

  @media (max-width: ${(p) => p.theme.screen.lg}) {
    grid-column-gap: 24px;
    grid-template-columns: 1fr 380px;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background: ${p.theme.color.secondary};
  }
`;

const listItemTile = (p) => css`
  position: relative;
  @media (max-width: ${(p) => p.theme.screen.md}) {
    margin-bottom: 60px;
  }

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    margin-bottom: 40px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background: ${p.theme.color.secondary};
  }
`;

const listRow = (p) => css`
  display: grid;
  grid-template-rows: ${p.hasOnlyOneArticle ? '1fr' : '1fr 1fr'};
`;

const showDetails = css`
  p {
    display: -webkit-box;
  }
  h2 {
    margin-bottom: 10px;
  }
`;

const listTile = (p) => css`
  position: relative;
  display: grid;
  grid-template-columns: ${p.reverse
    ? `${narrow} ${wide}`
    : `${wide} ${narrow}`};
  grid-template-rows: 2;
  column-gap: 30px;
  &:not(:last-child) {
    margin-bottom: 75px;
  }

  @media (max-width: ${p.theme.screen.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${p.theme.screen.sm}) {
    grid-template-columns: 1fr;
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  background: ${(p) => p.theme.color.secondary};
  height: ${(p) => (p.gridLayout === 'tiles' ? '280px' : '220px')};
  box-shadow: 0 30px 60px -10px rgba(0, 0, 0, ${(p) => (p.narrow ? 0.22 : 0.3)}),
    0 18px 36px -18px rgba(0, 0, 0, ${(p) => (p.narrow ? 0.25 : 0.33)});
  margin-bottom: ${(p) => (p.gridLayout === 'tiles' ? '30px' : 0)};
  transition: transform 0.3s var(--ease-out-quad),
    box-shadow 0.3s var(--ease-out-quad);

  & > div {
    height: 100%;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    height: 200px;
    margin-bottom: 35px;
  }

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    overflow: hidden;
    margin-bottom: 0;
    box-shadow: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
`;

const ArticleLink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: ${(p) => p.theme.color.font.regular};
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  z-index: 1;
  transition: transform 0.33s var(--ease-out-quart);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:hover ${ImageContainer}, &:focus ${ImageContainer} {
    transform: translateY(-1px);
    box-shadow: 0 50px 80px -20px rgba(0, 0, 0, 0.27),
      0 30px 50px -30px rgba(0, 0, 0, 0.3);
  }

  &:hover h2,
  &:focus h2 {
    text-decoration-line: underline;
    text-decoration-style: dotted;
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -1.5%;
    top: -2%;
    width: 103%;
    height: 104%;
    border: 3px solid ${(p) => p.theme.color.font.dim};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    &:hover ${ImageContainer} {
      transform: none;
      box-shadow: initial;
    }
    &:active {
      transform: scale(0.97) translateY(3px);
    }
  }
`;

const Tag = styled.span`
  display: block;
  font-family: ${(p) => p.theme.font.markdown};
  font-size: 0.9rem;
  line-height: 1em;
  font-weight: 600;
  color: ${(p) => p.theme.color.font.link};
  text-transform: uppercase;
  margin-bottom: 10px;
  transition: color 0.3s ease-in-out;

  @media (max-width: ${(p) => p.theme.screen.lg}) {
    margin-bottom: 15px;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
  }

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    padding: 30px 20px 0;
    margin-bottom: 10px;
  }
`;

const Title = styled.h2`
  font-family: ${(p) => p.theme.font.secondary};
  ${(p) => p.theme.font_size.regular};
  margin-bottom: ${(p) =>
    p.hasOverflow && p.gridLayout === 'tiles' ? '35px' : '10px'};
  transition: color 0.3s ease-in-out;
  text-decoration: none;
  ${limitToTwoLines};

  @media (max-width: ${(p) => p.theme.screen.lg}) {
    margin-bottom: 15px;
  }

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    padding: ${(p) => (p.tagged ? '0 0 0 20px' : '30px 20px 0;')};
    margin-bottom: 10px;
    -webkit-line-clamp: 3;
  }
`;

const Excerpt = styled.p`
    ${limitToTwoLines};
    font-family: ${(p) => p.theme.font.markdown};
    ${(p) => p.theme.font_size.xsmall};
    margin-bottom: 10px;
    color: ${(p) => p.theme.color.font.regular};
    display: ${(p) =>
      p.hasOverflow && p.gridLayout === 'tiles' ? 'none' : 'box'};
    max-width: ${(p) => (p.narrow ? '415px' : '515px')};

    @media (max-width: ${(p) => p.theme.screen.lg}) {
        display: -webkit-box;
    }

    @media (max-width: ${(p) => p.theme.screen.sm}) {
        margin-bottom; 15px;
        max-width: 100%;
        padding:  0 20px;
        margin-bottom: 20px;
        -webkit-line-clamp: 3;
    }
`;

const MetaData = styled.div`
  font-family: ${(p) => p.theme.font.markdown};
  font-weight: 600;
  font-size: 16px;
  color: ${(p) => p.theme.color.font.regular};
  opacity: 0.33;

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    max-width: 100%;
    padding: 0 20px 30px;
  }
`;

const DynamicImage = styled(GatsbyImage)`
  filter: invert(${(p) => (p.theme.invertImages ? 1 : 0)});
  transition: filter 0.2s ease-out;
`;
